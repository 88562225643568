import './App.css';
import ChangeColors from './Page/ChangeColors';


function App() {
  return (
    <div className="container">

      <ChangeColors/>
    </div>
  );
}

export default App;
